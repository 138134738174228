import { PublicClientApplication } from '@azure/msal-browser';
function getCurrentUrl() {
    if (window.location.hostname.match('dev')) {
        return 'https://dev.speedio.com.br';
    }
    if (window.location.hostname.match('staging')) {
        return 'https://staging.speedio.com.br';
    }
    if (window.location.hostname.match('test')) {
        return 'https://test.speedio.com.br';
    }
    if (window.location.hostname.match('app')) {
        return 'https://app.speedio.com.br';
    }
    return 'http://localhost:8080';
}
const msalConfig = {
    auth: {
        clientId: '9cc0b070-c73e-4701-87cc-90d18d19cedc',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: getCurrentUrl(),
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};
const msalInstance = new PublicClientApplication(msalConfig);
export { msalInstance };
