var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
let Google = class Google extends Vue {
    constructor() {
        super(...arguments);
        this.clientId = '1078379902561-01sn8qtj1n0b12077vo8k4lpc5139rco.apps.googleusercontent.com';
        this.onresize = event => {
            const width = event.target.window.innerWidth;
            this.buttonResize(width);
        };
    }
    handleGoogleCallbackResponse(response) {
        this.createNewAccount(response.credential, 'google');
    }
    async createNewAccount(token, social) {
        let data = {
            social_type: social,
            token_id: token,
        };
        this.$emit('loginWithSocialMedia', data);
    }
    mounted() {
        /*global google*/
        /* @ts-ignore */
        google.accounts.id.initialize({
            client_id: this.clientId,
            callback: this.handleGoogleCallbackResponse,
        });
        this.buttonResize(window.innerWidth);
    }
    created() {
        window.addEventListener('resize', this.onresize);
    }
    destroyed() {
        window.removeEventListener('resize', this.onresize);
    }
    buttonResize(width) {
        if (width < 1601) {
            this.renderGoogleButton(document.getElementById('signInDiv'), 300);
        }
        else {
            this.renderGoogleButton(document.getElementById('signInDiv'), 330);
        }
    }
    renderGoogleButton(element, width) {
        const options = {
            shape: 'circle',
            width: width,
            'max-width': '100%',
        };
        //@ts-ignore
        //"@typescript-eslint/no-redeclare": ["error"]
        google.accounts.id.renderButton(element, options);
    }
};
__decorate([
    Prop({ default: {} })
], Google.prototype, "plgData", void 0);
Google = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        name: 'Google',
        components: {},
    }))
], Google);
export default Google;
