var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import Facebook from './SocialMedias/Facebook.vue';
import Google from './SocialMedias/Google.vue';
import Linkedin from './SocialMedias/Linkedin.vue';
import Microsoft from './SocialMedias/Microsoft.vue';
let SocialForm = class SocialForm extends Vue {
    authentication(token) {
        this.$emit('authentication', token);
    }
    async loginWithSocialMedia(data) {
        data = { ...data, ...this.plgData };
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        data['rat'] = urlParams.get('rat');
        //@ts-ignore
        const create_account = await this.users.createAccountWithSocialMedia(data);
        if (create_account &&
            create_account.data &&
            create_account.data.authentication_token) {
            return this.authentication(create_account.data.authentication_token);
        }
        else {
            this.$q.notify({
                message: `Ocorreu um erro ao logar com ${data.social_type.charAt(0).toUpperCase() + data.social_type.slice(1)}`,
                type: 'error',
            });
        }
    }
};
__decorate([
    Prop({ default: {} })
], SocialForm.prototype, "plgData", void 0);
SocialForm = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        name: 'SocialForm',
        components: {
            Linkedin,
            Google,
            Facebook,
            Microsoft,
        },
    }))
], SocialForm);
export default SocialForm;
