var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import IconLinkedin from '@/components/Icons/SocialMedia/IconLinkedin.vue';
let Linkedin = class Linkedin extends Vue {
    constructor() {
        super(...arguments);
        this.opened = false;
    }
    windowParams() {
        const width = 600;
        const height = 800;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = (screenWidth - width) / 2;
        const top = (screenHeight - height) / 2;
        return {
            width,
            height,
            left,
            top,
        };
    }
    getCurrentUrl() {
        if (window.location.hostname.match('dev')) {
            return 'https://dev.speedio.com.br';
        }
        if (window.location.hostname.match('staging')) {
            return 'https://staging.speedio.com.br';
        }
        if (window.location.hostname.match('test')) {
            return 'https://test.speedio.com.br';
        }
        if (window.location.hostname.match('app')) {
            return 'https://app.speedio.com.br';
        }
        return 'http://localhost:8080';
    }
    loginWithLinkedin() {
        const apiKey = '7890t954enw9xb';
        const permissions = [
            'r_emailaddress',
            'r_liteprofile',
            'openid',
            'profile',
            'email',
        ];
        const callbackUrl = `${this.getCurrentUrl()}/linkedin/callback`;
        const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=${permissions.toString()}&client_id=${apiKey}&redirect_uri=${callbackUrl}`;
        const params = this.windowParams();
        if (this.opened)
            return;
        let Linkedin = window.open(url, '_blank', `width=${params.width}, height=${params.height}, left=${params.left}, top=${params.top}`);
        this.opened = true;
        const handleListener = e => {
            if (e.origin !== this.getCurrentUrl())
                return;
            Linkedin.close();
            let data = {
                social_type: 'linkedin',
                token_id: e.data.authCode,
            };
            this.$emit('loginWithSocialMedia', data);
            window.removeEventListener('message', handleListener, false);
        };
        window.addEventListener('message', handleListener, false);
        const closed = () => {
            this.opened = false;
        };
        var timer = setInterval(function () {
            if (Linkedin.closed) {
                clearInterval(timer);
                window.removeEventListener('message', handleListener, false);
                closed();
            }
        }, 1000);
    }
};
Linkedin = __decorate([
    Component({
        components: {
            IconLinkedin,
        },
    })
], Linkedin);
export default Linkedin;
