var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { msalInstance } from '@/boot/microsoftAuth';
import IconMicrosoft from '@/components/Icons/SocialMedia/IconMicrosoft.vue';
let Microsoft = class Microsoft extends Vue {
    signIn() {
        const loginRequest = {
            scopes: ['openid', 'profile', 'User.Read'],
        };
        msalInstance
            .loginPopup(loginRequest)
            .then(response => {
            let data = {
                social_type: 'microsoft',
                token_id: response.accessToken,
            };
            this.$emit('loginWithSocialMedia', data);
        })
            .catch(error => {
            console.error('Erro ao fazer login:', error);
        });
    }
};
Microsoft = __decorate([
    Component({
        components: {
            IconMicrosoft,
        },
    })
], Microsoft);
export default Microsoft;
