var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import { connect } from '@/overmind';
import RegisterForm from '@/components/SignUp/Form.vue';
import FullLoading from '@/components/Loadings/FullLoading.vue';
import IncompatibleForMobile from '@/components/IncompatibilityMode/incompatibleForMobile.vue';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        // resetPassword = false
        this.loading = false;
        this.pageLoading = false;
        this.errorKey = '';
    }
};
Login = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        name: 'Login',
        components: {
            Input,
            IncompatibleForMobile,
            RegisterForm,
            FullLoading,
        },
    }))
], Login);
export default Login;
