var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch, Emit, } from 'vue-property-decorator';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import IconEye from '@/components/Icons/iconEye.vue';
import Alert from '../Alert.vue';
import { connect } from '@/overmind';
let SignUpForm = class SignUpForm extends Vue {
    constructor() {
        super(...arguments);
        this.btnLoading = false;
        this.disableFields = true;
        this.errorField = '';
        this.showPass = false;
        this.showPassField = false;
        this.data = {
            email: '',
            password: '',
        };
        this.oldData = {
            email: '',
            password: '',
        };
        this.errorList = {
            auth: {
                message: 'E-mail ou senha estão incorretos.',
            },
            emptyFields: {
                message: '<b>Ops!</b> O Campo de e-mail precisa ser preenchido.',
            },
            recoveryPass: {
                message: 'Verifique se o e-mail digitado está correto e tente novamente!',
            },
            hasAuth: {
                message: '<b>Usuário já cadastado!</b> Tente com outros dados ou faça login para continuar ',
            },
            general: {
                message: 'Erro inesperado, entre em contato com o suporte.',
            },
            authError: {
                message: '<b>Ops!</b> Encontramos um erro ao autenticar sua conta, tente novamente ou chame o suporte.',
            },
            recoveryPassword: {
                message: 'Enviamos as informações para seu <b>e-mail</b> para a recuperação de senha',
            },
            accountIdentifyError: {
                message: '<b>Ops!</b> Ocorreu um erro na identificação da sua conta, por favor, tente novamente mais tarde.',
            },
        };
    }
    async mounted() {
        //add reactivation credits on login
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.data['rat'] = urlParams.get('rat');
        if (this.preData.email) {
            this.data.email = this.preData.email;
            this.handleSignIn();
        }
        await this.$recaptchaLoaded();
        const recaptcha = this.$recaptchaInstance;
        recaptcha.showBadge();
    }
    unmounted() {
        const recaptcha = this.$recaptchaInstance;
        recaptcha.hideBadge();
    }
    resetError(val) {
        this.disableFields = val ? false : true;
        if (this.showPassField) {
            for (let each in this.data) {
                if (this.data[each] == '')
                    this.disableFields = true;
            }
        }
        if (!this.disableFields)
            return (this.errorField = '');
    }
    resetFields() {
        for (let each in this.data) {
            this.oldData[each] = this.data[each];
            this.data[each] = '';
        }
    }
    async handleSignIn() {
        if (this.btnLoading)
            return;
        this.btnLoading = true;
        if (!this.showPassField) {
            await this.$recaptchaLoaded();
            const recaptchaToken = await this.$recaptcha('login');
            //@ts-ignore
            const user = await this.users.getUserExists({
                email: this.data.email,
                captcha: recaptchaToken,
            });
            if (user && user.data && !user.data.errors) {
                const isNewUserWithoutPassword = user.data.exist && !user.data.have_password;
                //enable password field
                if (!isNewUserWithoutPassword) {
                    this.showPassField = true;
                    this.btnLoading = false;
                    this.disableFields = true;
                }
                else {
                    // send to page token
                    return this.emitUserData();
                }
            }
            else {
                this.setErrorField('accountIdentifyError');
            }
            return;
        }
        await this.$recaptchaLoaded();
        const recaptchaToken = await this.$recaptcha('login');
        //@ts-ignore
        const authentication = await this.users.tryAuthLogin({
            ...this.data,
            captcha: recaptchaToken,
        });
        if (authentication &&
            authentication.data &&
            authentication.data.authentication_token) {
            const auth_token = authentication.data.authentication_token;
            this.$emit('authentication', auth_token);
            return;
        }
        else {
            this.setErrorField('authError');
            //this.resetFields()
            //this.showPassField = false
        }
    }
    emitUserData() {
        return this.data;
    }
    emitOldData() {
        return this.oldData;
    }
    setErrorField(field) {
        this.btnLoading = false;
        return (this.errorField = field);
    }
    handleShowPass() {
        return (this.showPass = !this.showPass);
    }
    async openRecovery() {
        this.setErrorField('recoveryPassword');
        // @ts-ignore TS2349: property inexistent
        await this.users.resetPassword({
            email: this.data.email,
        });
    }
};
__decorate([
    Prop({ default: { email: '' } })
], SignUpForm.prototype, "preData", void 0);
__decorate([
    Watch('data.email'),
    Watch('data.password')
], SignUpForm.prototype, "resetError", null);
__decorate([
    Emit('data')
], SignUpForm.prototype, "emitUserData", null);
__decorate([
    Emit('oldData')
], SignUpForm.prototype, "emitOldData", null);
__decorate([
    Watch('errorKey')
], SignUpForm.prototype, "setErrorField", null);
SignUpForm = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        name: 'SignUpForm',
        components: {
            Alert,
            IconSpeedioSpinner,
            IconEye,
        },
    }))
], SignUpForm);
export default SignUpForm;
