var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import IconFacebook from '@/components/Icons/SocialMedia/IconFacebook.vue';
let Facebook = class Facebook extends Vue {
    mounted() {
        //@ts-ignore
        window.fbAsyncInit = function () {
            /*global FB*/
            //@ts-ignore FB Javascript SDK configuration and setup
            FB.init({
                appId: '1388874191926238',
                cookie: true,
                xfbml: true,
                version: 'v3.2',
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id))
                return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            //@ts-ignore
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }
    loginWithFacebook() {
        const emitSocialData = data => {
            this.$emit('loginWithSocialMedia', data);
        };
        //@ts-ignore
        FB.login(function (response) {
            if (response.authResponse) {
                let data = {
                    social_type: 'facebook',
                    token_id: response.authResponse.accessToken,
                };
                emitSocialData(data);
            }
        }, { scope: 'email' });
    }
};
Facebook = __decorate([
    Component({
        components: {
            IconFacebook,
        },
    })
], Facebook);
export default Facebook;
